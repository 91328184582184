module.exports = [{
      plugin: require('/Users/chaquea/Sites/adclanding/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"blue","showSpinner":false},
    },{
      plugin: require('/Users/chaquea/Sites/adclanding/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/drupal-icon.png"},
    },{
      plugin: require('/Users/chaquea/Sites/adclanding/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
